import { CONTACTS } from "./contacts";
import { PRESENTATION } from "./presentation";
import { PARTNERS } from "./partners"
import { SELO_QUALIDADE } from "./seloQualidade";

export const CONTENT = {
  navbar: {
    home: 'Home',
    about: 'Sobre nós',
    history: 'Nossa história',
    services: 'Nossos serviços',
    contact: 'Contato',
    presentation: 'Apresentação',
    call: '(21) 98117-5281',
    associatedBuildings: 'Condomínios associados',
    seloQualidade: 'Selo de Qualidade'
  },
  intro: {
    infos: [
      {
        id: 0,
        src: 'assets/equipe-toda.jpg',
        alt: 'Diretoria ACFJ',
        title: 'Diretoria ACFJ'
      },
      {
        id: 1,
        src: 'assets/localizacao.jpg',
        alt: 'Localização da ACFJ',
        title: 'Localização da ACFJ'
      },
      {
        id: 2,
        src: 'assets/paisagem.jpg',
        alt: 'Paisagem',
        title: 'Paisagem'
      },
    ]
  },
  about: {
    breadcrumb: 'Sobre',
    page_title: 'A ACFJ',
    image: {
      src: `/assets/logo-acfj-2.png`,
      alt: 'Logo ACFJ'
    },
    title: 'Sobre nós',
    paragraphs: [
      {
        id: 1,
        text: 'A Associação de Condomínios da Freguesia de Jacarepaguá é uma associação civil sem fins lucrativos constituída em janeiro de 2023 por um grupo de 12 síndicos moradores do bairro da Freguesia.'
      },
      {
        id: 2,
        text: ' Em comum, um forte sentimento de pertencimento ao bairro e um sincero desejo de contribuir para a melhoria da qualidade de vida, do comércio e dos serviços locais, buscando, a partir de cada condomínio associado, incentivar a coletividade à participação, direta ou indireta, em iniciativas com impacto social, ambiental e urbanístico em sua área de abrangência.'
      }
    ],
    button_label: 'Saiba mais'
  },
  presentation: {
    breadcrumb: 'Apresentação',
    page_title: 'Diretoria ACFJ',
    infos: PRESENTATION
  },
  contact: {
    breadcrumb: 'Contato',
    page_title: 'Fale conosco',
    infos: CONTACTS
  },
  associatedBuildings: {
    breadcrumb: 'Condomínios associados',
    infos: PARTNERS
  },
  seloQualidade: {
    breadcrumb: 'Selo de Qualidade',
    infos: SELO_QUALIDADE
  }
}