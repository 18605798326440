import { GenericCard } from "../../../../components/GenericCard"
import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"
import { CONTENT } from "../../../../utils/mockData/homeContent"

export const Partners = () => {
  return (
    <section
      id={NAVBAR_PATHS.associatedBuildings}
      className="row align-items-center justify-content-center p-5 m-0"
    >
      <p className="lead text-uppercase text-center fw-medium"> {CONTENT.associatedBuildings.breadcrumb} </p>
      <div className="d-flex flex-wrap gap-3 justify-content-center">
        {
          CONTENT.associatedBuildings.infos.map(({ id, image, title }) => (
            <GenericCard
              key={id}
              cardTitle={title}
              cardImageName={image.src}
              cardImageAlt={image.alt}
              defaultWidth="350px"
            />
          ))
        }
      </div>
    </section>
  )
}