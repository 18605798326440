import Button from 'react-bootstrap/Button'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { CONTENT } from '../../../../utils/mockData/homeContent';
import { openPage } from '../../../../utils/helpers/window';
import { LINKS } from '../../../../utils/mockData/links';

export const Intro = () => {
  return (
    <section className="min-vh-100 w-100 d-flex intro-container mb-3">
      <div
        className="child d-flex justify-content-center align-items-center flex-column mx-lg-5 mx-md-4 p-4"
      >
        <h1 className="text-white display-2"> Unidos por um <strong className="fw-bold">bairro melhor</strong> </h1>
        <p className="text-white d-flex align-items-center text-uppercase">
          <span
            className="bg-danger" style={{ width: '100px', height: '4px', display: 'block', marginRight: '10px' }}></span>
          Associação de Condomínios da Freguesia de Jacarepaguá
        </p>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-4 flex-wrap">
          <Button variant="danger" size="lg" className="text-white shadow" style={{ minWidth: '186px' }} onClick={() => openPage(LINKS.instagram)}> Saiba mais </Button>
          <Button variant="danger" size="lg" className="text-white shadow" onClick={() => openPage(LINKS.wpp)}> Entre em contato </Button>
        </div>
      </div>
      <div className="slider-container" style={{ height: "75vh" }}>
        <Swiper
          className="mt-3 h-100"
          slidesPerView={'auto'}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            stopOnLastSlide: false
          }}
        >
          {
            CONTENT.intro.infos.map(({ src, alt, title, id }) => (
              <SwiperSlide key={id} className="d-flex justify-content-center align-items-center p-5">
                <img className="rounded-4 shadow flex-grow-1" src={src} alt={alt} title={title} style={{ maxWidth: '100%' }} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  )
}