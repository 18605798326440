export const CONTACTS = [
  {
    id: 1,
    title: 'Endereço',
    icon: 'location-pin',
    texts: [
      {
        id: 11,
        value: 'R. Araguaia, 3 - Freguesia de Jacarepaguá',
        link: 'https://www.google.com/maps/place/R.+Araguaia,+3+-+Freguesia+de+Jacarepagu%C3%A1,+Rio+de+Janeiro+-+RJ,+22745-270/@-22.9382395,-43.3473263,17z/data=!3m1!4b1!4m6!3m5!1s0x9bd8364118c9d1:0x2048567fb38e0b44!8m2!3d-22.9382395!4d-43.3473263!16s%2Fg%2F11cncj1xz4?entry=ttu'
      }
    ]
  },
  {
    id: 2,
    title: 'E-mail',
    icon: 'envelope',
    texts: [
      {
        id: 21,
        value: 'acondominiosfreguesiajpa@gmail.com',
        link: 'mailto:acondominiosfreguesiajpa@gmail.com'
      }
    ]
  }
]