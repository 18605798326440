export const PRESENTATION = [
  {
    id: 312893892,
    image: {
      src: '/assets/andre.jpg',
      alt: 'Foto André Fonseca'
    },
    title: 'André Fonseca',
    paragraphs: [
      {
        id: 389932,
        text: 'Administrador, após consolidar a sua carreira profissional na área de transportes, André começou a fazer trabalhos Sociais, primeiro como voluntário, depois fundou a IFIP (Instituição Familiar Irmão Paulo) em 2005, onde foram feitos trabalhos de reforço escolar e iniciação na área de informática.'
      },
      {
        id: 312312,
        text: 'No final do ano de 2016 fundou a Casa Social de Cáritas, nesse ONG foram feitos trabalhos de inclusão Social para crianças e adolescentes em vulnerabilidade sócio econômico por meio de aulas de violino em parceria com a Agência do Bem.'
      },
      {
        id: 3127832,
        text: 'No início do ano de 2018 iniciou uma parceria com o Lar de Maria Dolores, levando para essa casa as aulas de violino, flauta doce e a percussão. Depois desses treze anos atuando no terceiro setor levando oportunidades para esses jovens, chegou a conclusão que seria muito mais eficiente montar uma Agência voltada para dar apoio a outras instituições já constituídas, nas áreas administrativas, operacionais e principalmente viabilizando a captação de recursos, foi assim que nasceu a Agência Interagir e Renovar – Agir.'
      },
      {
        id: 12331283,
        text: 'Não satisfeito, dá início a um novo projeto para alavancar a economia local da Freguesia de Jacarepaguá, se tornando associado da ACIJA, onde começa atuando como gestor dos comitês, depois se torna Diretor de Responsabilidade Social, isso tudo em prol de um social mais justo, ai surge a oportunidade de exercer a mesma função na Rede Kai Rio, uma associação de Networking, muito atuante em vários bairros do Rio, antes disso, fez parte da diretoria da Associação dos Moradores da Freguesia (AMAF).'
      },
      {
        id: 3712887312,
        text: 'E para trazer mais força de representatividade para o bairro da Freguesia, se une a um grupo de pessoas com o mesmo sentimento de união por um local melhor para se viver, juntos conseguem fundar a Associação dos Condomínios da Freguesia de Jacarepaguá – ACFJ, onde vem se tornar presidente'
      }
    ]
  },
  {
    id: 54312312,
    image: {
      src: '/assets/nidiara.jpg',
      alt: 'Foto Nidiara'
    },
    title: 'Nidiara Barreto',
    paragraphs: [
      {
        id: 123831289,
        text: 'Formada em publicidade/propaganda e em design de moda, a GrowthHacker da A Solucionista Nidiara Barreto vem ganhando seu lugar ao sol aqui no Rio de Janeiro! Paulista de nascimento, Nidi como ela é chamada, vem registrando suas ações aqui pela Freguesia, mãe de duas princesas, esposa dedicada, síndica, mente brilhante da @asolucionista, agência de branding reconhecida no bairro por seus projetos em Jacarepaguá, ela enche o coração de luz ao falar de cada cliente e projeto. Secretária da ACFJ, @acondominiosfreguesiajpa (Associação dos Condomínios da Freguesia de Jacarepaguá).'
      },
      {
        id: 3721783129,
        text: 'Criadora da Expocond, @expocondjpa, a primeira feira voltada a síndicos e gestores de condomínios de Jacarepaguá.'
      }
    ]
  },
  {
    id: 12312234,
    image: {
      src: '/assets/bruno.jpg',
      alt: 'Foto Bruno'
    },
    title: 'Bruno do Amaral Gama',
    paragraphs: [
      {
        id: 312812,
        text: 'Advogado, Tesoureiro da 32ª Subseção da OAB/RJ. Possui graduação em Direito pela Universidade Gama Filho, Pós-graduação em Direito Processual Civil pela Universidade Cândido Mendes, Pós-graduação em Direito Imobiliário pela faculdade Cândido Mendes, Graduação em Teologia pela Faculdade de Teologia Wittenberg e Mestrado em História da Igreja pela Faculdade de Teologia Wittenberg.'
      }
    ]
  },
  {
    id: 483423234,
    image: {
      src: '/assets/jean.jpg',
      alt: 'Foto Jean Carlos Novaes'
    },
    title: 'Jean Carlos Novaes',
    paragraphs: [
      {
        id: 49129439,
        text: 'Graduado em Direito pela Universidade Federal de Minas Gerais no ano de 1994, advoga há 29 anos no mercado imobiliário. Há 20 anos atua como síndico. Atualmente, Presidente da Comissão de Direito Condominial da 32ª Subseção da OAB (Madureira/Jacarepaguá) e Vice-Presidente da Associação de Condomínios da Freguesia de Jacarepaguá, da qual é um dos fundadores.'
      }
    ]
  }
]