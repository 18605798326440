export const SELO_QUALIDADE = [
  {
    id: 1,
    image: {
      src: '/assets/expocond.jpg',
      alt: 'Logo ExpoCond Jacarepaguá'
    },
    title: 'ExpoCond Jacarepaguá',
    href: 'http://www.expocondjpa.com.br'
  },
  {
    id: 2,
    image: {
      src: '/assets/novaesgama.jpg',
      alt: 'Logo Novaes & Gama Advogados'
    },
    title: 'Novaes & Gama Advogados',
    href: 'https://novaesegama.com.br/'
  },
  {
    id: 3,
    image: {
      src: '/assets/asolucionista.jpg',
      alt: 'Logo A Solucionista'
    },
    title: 'Agência de Publicidade, Marketing e Eventos.',
    href: 'https://asolucionista.com/'
  },
]