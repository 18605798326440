import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NAVBAR_PATHS } from '../../utils/constants/Paths';
import { CONTENT } from '../../utils/mockData/homeContent';
import { openPage } from '../../utils/helpers/window';
import { LINKS } from '../../utils/mockData/links';

export const GenericNavbar = () => {
  return (
    <header>
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          <Navbar.Brand className="bg-white py-1 px-3 rounded-2">
            <img src="/assets/logo-acfj.png" alt="Logo ACFJ" width="30" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={`#${NAVBAR_PATHS.about}`} className="nav-link text-white">
                {CONTENT.navbar.about}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.presentation}`} className="nav-link text-white">
                {CONTENT.navbar.presentation}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.associatedBuildings}`} className="nav-link text-white">
                {CONTENT.navbar.associatedBuildings}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.seloQualidade}`} className="nav-link text-white">
                {CONTENT.navbar.seloQualidade}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.contact}`} className="nav-link text-white">
                {CONTENT.navbar.contact}
              </Nav.Link>
            </Nav>

            <Button className="d-flex align-items-center" variant="outlined" type="button" onClick={() => openPage(`${LINKS.wpp}/?text=Digite%20sua%20dúvida`)}>
              <span className="p-2 d-flex justify-content-center align-items-center bg-danger rounded-circle mx-2">
                <FontAwesomeIcon
                  className="fs-5 text-white"
                  icon={['fab', 'whatsapp']}
                />
              </span>
              <strong>
                {CONTENT.navbar.call}
              </strong>
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
