import { GenericCard } from '../../../../components/GenericCard'
import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const Presentation = () => {
  return (
    <section
      id={NAVBAR_PATHS.presentation}
      className="row align-items-center justify-content-center p-5 m-0"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.presentation.breadcrumb} </p>
      <h2 className="text-body-emphasis lh-1 mb-5 text-center">{CONTENT.presentation.page_title}</h2>
      <div className="d-flex flex-wrap gap-3 justify-content-center">
        {
          CONTENT.presentation.infos.map(({ id, image, title, paragraphs }) => (
            <GenericCard
              key={id}
              cardTitle={title}
              cardImageName={image.src}
              cardImageAlt={image.alt}
              cardParagraphs={paragraphs}
            />
          ))
        }
      </div>
    </section>
  )
}