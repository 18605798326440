export const PARTNERS = [
  {
    id: 1,
    image: {
      src: '/assets/promenade.jpg',
      alt: 'Logo condomínio Promenade Geminiano Góis'
    },
    title: 'Condomínio Promenade Geminiano Góis'
  },
  {
    id: 2,
    image: {
      src: '/assets/village.jpg',
      alt: 'Logo condomínio Village Botanique'
    },
    title: 'Condomínio Village Botanique'
  },
  {
    id: 3,
    image: {
      src: '/assets/reserva-freguesia.png',
      alt: 'Logo condomínio Reserva Freguesia'
    },
    title: 'Condomínio Reserva Freguesia'
  },
  {
    id: 4,
    image: {
      src: '/assets/emerald-coast.png',
      alt: 'Logo condomínio Emarald Coast'
    },
    title: 'Condomínio Emarald Coast'
  },
]