import Button from 'react-bootstrap/Button'
import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'
import { openPage } from "../../../../utils/helpers/window"
import { LINKS } from '../../../../utils/mockData/links'

export const About = () => {
  return (
    <section
      id={NAVBAR_PATHS.about}
      className="row align-items-center justify-content-center p-5 m-0"
      style={{ backgroundImage: 'url(/assets/jac-bg.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <p className="lead text-uppercase text-center"> {CONTENT.about.breadcrumb} </p>
      <h2 className="text-body-emphasis lh-1 mb-5 text-center">{CONTENT.about.page_title}</h2>
      <div className="col-10 col-sm-8 col-lg-6">
        <img
          src={`${CONTENT.about.image.src}`}
          className="img-fluid shadow rounded"
          alt={CONTENT.about.image.alt}
          width="700"
          height="500"
          loading="lazy"
        />
      </div>
      <div className="col-lg-6 mt-3 mt-lg-0">
        <h3 className="fs-3">{CONTENT.about.title}</h3>
        {
          CONTENT.about.paragraphs.map(({ text, id }) => (
            <p className="lead" key={id}>{text}</p>
          ))
        }
        <Button type="button" onClick={() => openPage(`${LINKS.wpp}/?text=Gostaria%20de%20saber%20mais%20sobre%20a%20Jac%20Grupo`)} className="btn btn-primary btn-lg px-4 me-md-2">{CONTENT.about.button_label}</Button>
      </div>
    </section>
  )
}