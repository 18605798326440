import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card'
import { openPage } from '../../utils/helpers/window'

export const GenericCard = ({ cardTitle, cardText, cardParagraphs, cardImageName, cardImageAlt, defaultWidth = '250px', onClick }) => {
  const CARD_WIDTH = defaultWidth || '250px'
  const CARD_HEIGHT = defaultWidth === '250px' ? '525px' : '350px'

  const cardBodyRef = useRef()
  const [hasScrollbar, setHasScrollbar] = useState(false)

  const handleClick = () => {
    if (!onClick) return
    openPage(onClick)
  }

  useEffect(() => {
    if (!cardBodyRef?.current) return
    const cardBodyScrollHeight = cardBodyRef.current.scrollHeight
    const cardBodyHeight = cardBodyRef.current.clientHeight
    if (cardBodyScrollHeight > cardBodyHeight) setHasScrollbar(true)
  }, [])

  return (
    <Card
      className="p-3 shadow"
      style={{ width: CARD_WIDTH, maxWidth: CARD_WIDTH, maxHeight: CARD_HEIGHT, cursor: onClick ? 'pointer' : 'auto' }}
      onClick={handleClick}
    >
      <Card.Img
        variant="top"
        src={`${cardImageName}`}
        alt={`${cardImageAlt}`}
        height={defaultWidth === '250px' ? '325' : 'auto'}
        style={{ width: defaultWidth === '250px' ? '100%' : 'auto', maxHeight: defaultWidth === '250px' ? 'auto' : '80%', margin: '0 auto', maxWidth: '100%' }}
      />
      <Card.Title className={`mt-2 ${defaultWidth !== '250px' ? 'custom-card-title' : ''}`}>{cardTitle}</Card.Title>
      {
        (cardText || cardParagraphs) && (
          <Card.Body className="overflow-y-auto" ref={cardBodyRef}>
            {
              cardParagraphs?.length ? (
                <>
                  {cardParagraphs.map(({ id, text }) => (
                    <Card.Text key={id}>{text}</Card.Text>
                  ))}
                </>
              ) : (
                <Card.Text> {cardText} </Card.Text>
              )
            }
          </Card.Body>
        )
      }
      {
        hasScrollbar && (
          <span className="arrow-sinalization">
            <FontAwesomeIcon className="text-primary" icon={['fas', 'arrow-down']} />
          </span>
        )
      }
    </Card>
  )
}
